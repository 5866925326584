import React from "react";
export const ContactSection = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6">About Us</h2>
            <p className="text-lg text-gray-500">
              SiteWorks is a not-for-profit professional association dedicated
              to advancing technical excellence in data engineering, cloud
              development, and specialized analysis. We bring together experts
              and practitioners from federal agencies and private industry to
              foster collaboration, share knowledge, and promote best practices
              in technical innovation.
            </p>
          </div>
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              Contact Us
            </h2>
            <div className="space-y-8">
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  For Technical Professionals
                </h3>
                <p className="text-gray-500">
                  Interested in joining our association? Contact us at{" "}
                  <a
                    href="mailto:join@site.works"
                    className="text-teal-600 hover:text-teal-700 font-medium"
                  >
                    join@site.works
                  </a>
                </p>
              </div>
              <div className="pt-6 border-t border-gray-200">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  For Service Inquiries
                </h3>
                <p className="text-gray-500">
                  Looking to work with our members? Reach out to{" "}
                  <a
                    href="mailto:sales@site.works"
                    className="text-teal-600 hover:text-teal-700 font-medium"
                  >
                    sales@site.works
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
