import React from "react";
export const Footer = () => {
  return (
    <footer id="about" className="relative text-gray-300 overflow-hidden">
      <div className="absolute inset-0">
        <img
          src="siteworks-background.webp"
          alt=""
          className="w-full h-full object-cover object-center"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900/90 via-gray-900/85 to-gray-900/80" />
      </div>
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <img
              src="siteworks-logo.png"
              alt="Siteworks"
              className="h-8 mb-6 filter brightness-150"
            />
            <div className="space-y-3">
              <p className="text-sm">SiteWorks Members Assoc.</p>
              <p className="text-sm">
                8300 Greensboro Dr, Ste L1-631
                <br />
                Tysons Corner, VA 22102
              </p>
              <p className="text-sm">Phone: (703) 910-8080</p>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800/50">
          <p className="text-sm text-gray-400 text-center">
            © {new Date().getFullYear()} SiteWorks Members Assoc. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
