import React, { useState } from "react";
import { Navbar } from "./components/Navbar";
import { Hero } from "./components/Hero";
import { ServicesSection } from "./components/ServicesSection";
import { ResearchSection } from "./components/ResearchSection";
import { ContactSection } from "./components/ContactSection";
import { Footer } from "./components/Footer";
import { BlogArticle } from "./components/BlogArticle";

function App() {
  const [selectedArticle, setSelectedArticle] = useState(null);
  if (selectedArticle) {
    return (
      <div className="min-h-screen bg-white flex flex-col">
        <Navbar />
        <div className="flex-grow">
          <BlogArticle
            article={selectedArticle}
            onBack={() => setSelectedArticle(null)}
          />
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navbar />
      <div className="flex-grow">
        <Hero />
        <ServicesSection />
        <ResearchSection onArticleClick={setSelectedArticle} />
        <ContactSection />
      </div>
      <Footer />
    </div>
  );
}

export default App;
