import React from "react";
import {
  Database,
  Cloud,
  Brain
} from "lucide-react";
export const ServicesSection = () => {
  const services = [
    {
      title: "Data Engineering & Science",
      description:
        "Advanced data pipeline development, analytics, and machine learning solutions for complex data challenges.",
      icon: Database,
      subServices: [
        "Data Pipeline Development",
        "Machine Learning",
        "Analytics",
      ],
    },
    {
      title: "Cloud Development",
      description:
        "Secure cloud architecture design and implementation with modern DevOps practices.",
      icon: Cloud,
      subServices: ["AWS/Azure Solutions", "Infrastructure as Code", "CI/CD"],
    },
    {
      title: "Technical Analysis",
      description:
        "Specialized technical expertise and in-depth analysis for critical systems and processes.",
      icon: Brain,
      subServices: [
        "System Architecture",
        "Performance Analysis",
        "Security Assessment",
      ],
    },
  ];
  return (
    <section id="services" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">
            Core Capabilities
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Comprehensive technical solutions for federal agencies and private
            industry
          </p>
        </div>
        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white p-8 rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <service.icon className="h-10 w-10 text-teal-600 mb-6" />
              <h3 className="text-xl font-semibold text-gray-900">
                {service.title}
              </h3>
              <p className="mt-3 text-gray-500">{service.description}</p>
              <ul className="mt-4 space-y-2">
                {service.subServices.map((subService, idx) => (
                  <li
                    key={idx}
                    className="flex items-center text-sm text-gray-600"
                  >
                    <div className="w-1 h-1 bg-teal-600 rounded-full mr-2"></div>
                    {subService}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
