import React, {useRef, useEffect} from "react";
import { ArrowLeft } from "lucide-react";
interface BlogArticleProps {
  onBack: () => void;
  article: {
    title: string;
    category: string;
    date: string;
    content: string;
    author: {
      name: string;
      role: string;
    };
  };
}
export const BlogArticle = ({ article, onBack }: BlogArticleProps) => {
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Focus the div element when the component is mounted
    if (divRef.current) {
      divRef.current.focus();
    }
  }, []);

  return (
    <div ref={divRef} tabIndex={-1} className="min-h-screen bg-white">
      {/* Article Header */}
      <div className="relative bg-gray-50">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <button
            onClick={onBack}
            className="flex items-center text-teal-600 hover:text-teal-700 mb-8"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Home
          </button>
          <div>
            <div className="text-sm text-teal-600 font-semibold mb-2">
              {article.category}
            </div>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              {article.title}
            </h1>
            <div className="flex items-center text-gray-500 text-sm">
              <span>{article.date}</span>
              <span className="mx-2">•</span>
              <span>{article.author.name}</span>
              <span className="mx-2">•</span>
              <span>{article.author.role}</span>
            </div>
          </div>
        </div>
      </div>
      {/* Article Content */}
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="prose prose-lg prose-teal mx-auto">
          {article.content.split("\n").map((paragraph, index) => (
            <p key={index} className="text-gray-600 mb-6">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
