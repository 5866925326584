import React from "react";
export const Navbar = () => {
  return (
    <nav id="home" className="w-full bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0">
            <img
              src="siteworks-logo.png"
              alt="Siteworks"
              className="h-8"
            />
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-8">
              <a
                href="#home"
                className="text-gray-700 hover:text-teal-600 px-3 py-2"
              >
                Home
              </a>
              <a
                href="#services"
                className="text-gray-700 hover:text-teal-600 px-3 py-2"
              >
                Services
              </a>
              <a
                href="#research"
                className="text-gray-700 hover:text-teal-600 px-3 py-2"
              >
                Research
              </a>
              <a
                href="#about"
                className="text-gray-700 hover:text-teal-600 px-3 py-2"
              >
                About
              </a>
              <a
                href="#about"
                className="text-gray-700 hover:text-teal-600 px-3 py-2"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
